.user-info-modal {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);

  .category-label {
    height: 25px;
    width: 114px;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #fcc30f;
  }

  .user-info-modal_card {
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 500px;
    border-radius: 20px 20px 0 0;
    background-color: #ffffff;
    overflow-y: auto;
    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;
    }
  }

  .user-info-modal-header {
    position: sticky;
    top: 0;
    height: 112px;
    display: flex;
    align-items: flex-start;
    padding: 19px;
    padding-right: 8px;
    // border-bottom: 0.5px solid #979797;
    background: #ffffff;
    z-index: 1;
    .header-container {
      width: 100%;
      display: flex;
      align-items: center;

      .profile-img-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .profile-name-container {
        width: 108px;
        margin-left: 8px;
      }
      .profile-name {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.11px;
        line-height: 16px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .profile-category {
        display: flex;
        justify-content: start;
        span {
          border-radius: 7px;
          background: #000;
          font-size: 11px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          line-height: 1.45;
          letter-spacing: 0.1px;
          color: #fff;
          padding: 0 5px;
        }
      }
    }

    .position-container {
      width: 100%;
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;
      .narino-logo {
        width: 92px;
      }
    }

    .position-box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
    }

    .position-text {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.15px;
      line-height: 16px;
    }
    .ranking-difference {
      font-size: 12px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.11px;
      .up-icon {
        margin-left: 2px;
      }
    }

    .close-button {
      padding: 10px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 15px;
    }
    .close-icon {
      height: 14px;
      width: 14px;
      color: #252525;
    }
  }

  .user-info-modal_card-body {
    padding: 15px;
    display: flex;
    position: relative;
    flex-direction: column;

    .position-container {
      width: 100%;
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;
      .narino-logo {
        width: 92px;
      }
    }

    .position-box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
    }

    .position-text {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.15px;
      line-height: 16px;
    }
    .ranking-difference {
      font-size: 12px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.11px;
      .up-icon {
        margin-left: 2px;
      }
    }

    .segments-table {
      // margin-top: 30px;
      display: flex;
      justify-content: center;
      gap: 20px;
    }

    .position-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      .position-box {
        height: 32px;
      }
    }

    .segment-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    .type-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      .type-box {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        opacity: 0.7;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.89;
        letter-spacing: 0.17px;
        color: rgba(0, 0, 0, 0.85);
        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 32px;
        }
        .type-icon {
          height: 25px;
        }
      }
    }

    .table-title {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.11px;
      color: #000;
      margin-bottom: 10px;
    }

    .time-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 32px;
      .main-time {
        opacity: 0.7;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.89;
        letter-spacing: 0.17px;
        color: rgba(0, 0, 0, 0.85);
        .waiting {
          font-size: 10px;
        }
      }
      .partial-time {
        opacity: 0.7;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.85);
        .waiting {
          font-size: 8px;
        }
      }
    }
  }

  .profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: solid 5px;
    border-color: #fcc30f;
    &.female {
      border-color: #fc0f72;
    }
  }

  .profile-img-container {
    height: 110px;
    width: 100%;
  }
}

@media only screen and (min-width: 64em) {
  .user-info-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    .user-info-modal_card {
      position: relative;
      width: 500px;
      border-radius: 0;
    }
  }
}
