.empty-state {
  width: 100%;
  height: 100%;
  padding-top: 0;
  .empty-state_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (min-width: 64em) {
  .empty-state {
    width: 100%;
    height: 50%;
    padding-top: 0;
    .empty-state_img {
      width: 30%;
      height: 100%;
    }
  }
}
